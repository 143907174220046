import React from "react";
import { Link } from "react-router-dom";

const CategoryCard = (props) => {
  const handleClick = (id) => {

    if(props.id === 6){
      return;
    }
    sessionStorage.setItem("catId", id);
  };
  return (
    <>
      <Link to="/searchjob" onClick={() => handleClick(props.id)}>
        <div
          className="card catCard"
          style={{
            backgroundImage: `url(${props.bg_image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="card-body catCardBody">
            <h5 className="card-title catCardTitle">{props.title}</h5>
            {/* <h5 className="card-subtitle catCardSubtitle mb-2">{props.title2}</h5> */}
            <div className="ImageCard">
              <img className="imageChild1" src={props.image1} alt="" />
              <img className="imageChild2" src={props.image2} alt="" />
            </div>
            <h6 className="catCardFooter">{props.footer?.substring(0, 64)}</h6>
          </div>
        </div>
      </Link>
    </>
  );
};

export default CategoryCard;
