import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";

const ProfileCard = (props) => {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();
  const curr = Cookies.get("curr");
  const tokenClient = Cookies.get("tokenClient");
  const userType = Cookies.get("user_type");
  useEffect(() => {
    if (tokenClient && userType === "recruiter") {
      setAuthenticated(true);
    }
  }, []);

  const handleNavigate = (slug) => {
    if (authenticated) {
      navigate(`/candidates/profile/${slug}`);
    }
  };

  const handleDocDownload = async (documentUrl) => {
    // Create an anchor element
    const anchor = document.createElement("a");

    // Set the href attribute to the document URL
    anchor.href = documentUrl;

    // Set the download attribute to force the browser to download the file instead of navigating to it
    anchor.setAttribute("download", "");

    // Hide the anchor element
    anchor.style.display = "none";

    // Append the anchor element to the document body
    document.body.appendChild(anchor);

    // Trigger a click on the anchor element
    anchor.click();

    // Remove the anchor element from the document body after a short delay
    setTimeout(() => {
      document.body.removeChild(anchor);
    }, 100);
  };

  return (
    <div className="profileCard">
      <div className="firstSection text-center">
        {props.profile_image !== "" ? (
          <img src={props.profile_image} alt="" className="profileCardImage" />
        ) : (
          <img src="/Images/jobseekerSide/dummy-profile.png" alt="" style={{maxWidth: "138px", borderRadius: "25px"}}/>
        )}
      </div>
      <div className="secondSection text-center">
        <h2>
          {props.first_name} {props.last_name}
        </h2>
        <p className="text-muted">{props.role}</p>
      </div>
      <div className="thirdSection text-muted">
        <p>
          {curr}
          {props.exp_salary ? props.exp_salary + " " + "/Year" : " Not Available"}
        </p>
        <p>
          <i class="fa-solid fa-location-dot"></i> {props.location ? props.location : "Not Available"}
        </p>
      </div>
      <div className="fourthSection text-center">
        <Link to={`/candidates/profile/${props.slug}`}>
          <button>
            DOWNLOAD RESUME <i class="fa-solid fa-download downloadIcon"></i>
          </button>
        </Link>

        {/* {props.old_docs &&
          props.old_docs?.slice(0, 1).map((i, index) => {
            return (
              <>
               
                  <button onClick={() => handleDocDownload(i.path, i.doc)}>
                    DOWNLOAD RESUME {index + 1}
                    <i class="fa-solid fa-download downloadIcon"></i>
                  </button>
                
              </>
            );
          })} */}
      </div>
    </div>
  );
};



export default ProfileCard;


