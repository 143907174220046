import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const Testimonial = () => {
  const options = {
    // margin: 30,
    responsiveClass: true,
    // nav: true,
    autoplay: true,
    autoplaySpeed: 1000,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const testimonialData = [
    {
      name: "John Doe",
      position: "Manager",
      description: "Since utilising my CV I have had interviews at a number of English Premier League and SPFL clubs before accepting a role in the USA. It is definitely a service which I will continue to use.",
    },
    {
      name: "Carl Doe",
      position: "Client",
      description: "We’re proud to be associated with sports-job-finder for more than five years! We've found sports-job-finder to be the ideal partner in our search for the right talent since their mission is very much in sync with our own.",
    },
    {
      name: "Jane Doe",
      position: "Developer",
      description: "SportsJobFinder always surprises us with their ability to attract talent from across subject areas and functions. ",
    },
  ];

  return (
    <div className="testimonial">
      <div className="image">
        <img src="/Images/sportsjobfinder/quotes.png" alt="" />
      </div>
      <OwlCarousel
        className="owl-theme"
        autoplay
        autoplaySpeed={1000}
        center={true}
        loop
        margin={0}
        // items={3}
        // nav
        {...options}
      >
        {testimonialData.map((i) => {
          return (
            <>
              <div className="sliderDiv">
                <h2>{i.name}</h2>
                <p>{i.position}</p>
                <p className="testimonialDescription">{i.description}</p>
              </div>
            </>
          );
        })}
      </OwlCarousel>
    </div>
  );
};

export default Testimonial;
