import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";
import HTMLReactParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import Tooltip from "rc-tooltip";
const MembershipListing = () => {
  const [pageContent, setPageContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentLanguage = Cookies.get("selectedLanguage") || "en";
  const [t, i18n] = useTranslation("global");

  const [membershipData, setMembershipData] = useState([]);
  const [membershipFeatureData, setMembershipFeatureData] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);

  let userType = Cookies.get("user_type");
  let curr = Cookies.get("curr");
  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  const tokenKey = Cookies.get("tokenClient");

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(BaseApi + `/employerplans/listing`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setLoading(false);
        setMembershipData(response.data.response);
      } catch (error) {
        console.log("Cannot get home page data!");
      }
    };
    getData();
    if (
      tokenKey !== "" &&
      (userType === "recruiter" || userType === "candidate")
    ) {
      setAuthenticated(true);
    }
  }, []);

  const [hoverMembershipButtonColor, setHoverMembershipButtonColor] =
    useState(false);

  const handleMembershipButtonMouseEnter = () => {
    setHoverMembershipButtonColor(true);
  };

  const handleMembershipButtonMouseLeave = () => {
    setHoverMembershipButtonColor(false);
  };

  const handleBuyWithoutLogin = () => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "warning",
      title: t("userpage.pleaseLogin"),
    });
  };

  return (
    <>
      <NavBar />
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <div className="privacyPolicy membershipListing">
            <div className="text-center PPSection1">
              <h1 className="">Membership Plans</h1>
              <h6 className="text-muted fw-normal">
                {" "}
                <Link to="/" style={{ color: "grey" }}>
                  {t("navHeaders.home")}
                </Link>{" "}
                / Membership Plans
              </h6>
            </div>
            <div className="container">
              <div className="jobActionButtonsBx mb-5">
                <div className="jobsAction1">
                  {tokenKey ? (
                    <Link to="/alerts/add" className="jobActionButtons">
                      Create Job Alert
                    </Link>
                  ) : (
                    <Link
                      to="/user/jobseekerlogin"
                      className="jobActionButtons"
                    >
                      Create Job Alert
                    </Link>
                  )}
                </div>
                {tokenKey ? (
                  <Link
                    to="/candidates/addcvdocuments"
                    // type="button"
                    className="jobActionButtons"
                  >
                    {t("userpage.uploadCVButton")}
                  </Link>
                ) : (
                  <Link
                    to="/user/jobseekerlogin"
                    // type="button"
                    className="jobActionButtons"
                  >
                    {t("userpage.uploadCVButton")}
                  </Link>
                )}
              </div>
              <div className="row">
                <div
                  className="col-lg-9 mb-5 CLPanelRight"
                  style={{
                    borderLeft: "2px solid #e6e8e7",
                    borderRight: "2px solid #e6e8e7",
                  }}
                >
                  <div className="row">
                    {membershipData.map((i) => {
                      return (
                        <>
                          <div className="col-md-4 mt-5">
                            <div className="card text-center membershipCardMain">
                              <div className="item">
                                <div className="MembershipCardAuth">
                                  <div className="membershipCardAuthUpperPart">
                                    <h4>{i.plan_name}</h4>
                                    <h2
                                      style={{
                                        color: primaryColor,
                                      }}
                                    >
                                      {curr} {i.amount}
                                    </h2>
                                    <h6 className="mt-3">{i.plan_type}</h6>
                                  </div>

                                  <>
                                    <Link
                                    to="/user/employerlogin"
                                      className="btn btn-primary"
                                      style={{
                                        backgroundColor: primaryColor,
                                        border: primaryColor,
                                      }}
                                      // onClick={() =>
                                      //   activateFreePlan(
                                      //     i.plan_id,
                                      //     i.amount,
                                      //     i.currency,
                                      //     i.plan_name
                                      //   )
                                      // }
                                    >
                                      BUY THIS PLAN
                                    </Link>
                                  </>

                                  <div className="ApplyAllAuth text-center mb-4">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="applyimmediately"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="applyimmediately"
                                    >
                                      Apply Immediately
                                    </label>
                                    <Tooltip
                                      placement="top"
                                      overlay={
                                        <span>
                                          If you select this option plan will{" "}
                                          <br />
                                          be applied from today and any
                                          <br /> remaining feature of your
                                          current
                                          <br /> plan will not be carry
                                          forwarded.
                                        </span>
                                      }
                                    >
                                      <span>
                                        <i class="fa-solid fa-circle-info ms-2"></i>
                                      </span>
                                    </Tooltip>
                                  </div>
                                  <hr />
                                  <ul className="applyoptionAuth">
                                    {i.features.map((j) => {
                                      return (
                                        <>
                                          <li>
                                            {j.outer
                                              ? HTMLReactParser(j.outer)
                                              : ""}
                                            <Tooltip
                                              placement="top"
                                              overlay={
                                                <span>
                                                  {j.inner
                                                    ? HTMLReactParser(j.inner)
                                                    : ""}
                                                </span>
                                              }
                                            >
                                              <span>
                                                <i class="fa-solid fa-circle-info ms-2"></i>
                                              </span>
                                            </Tooltip>
                                          </li>
                                        </>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <div className="row">
            {membershipData.map((i) => {
              return (
                <>
                  <div className="col-md-4">
                    <div className="item">
                      <div className="MembershipCard">
                        <div
                          className="MembershipCardImage"
                          style={{ backgroundImage: `url(${i.bg_image})` }}
                        >
                          <h4 className="text-white text-center">
                            {i.plan_name}
                          </h4>
                          <h2 className="text-white text-center">
                            {curr} {i.amount}
                          </h2>
                          <h6 className="text-white text-center">
                            For {i.plan_type}
                          </h6>
                        </div>

                        <div className="ApplyAll">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="applyimmediately"
                          />
                          <label
                            className="form-check-label"
                            for="applyimmediately"
                          >
                            {t("userpage.applyImmediately")}
                          </label>
                        </div>
                        <ul className="applyoption">
                          {Object.entries(i.features).map(([key, value]) => {
                            return (
                              <li>
                                <i class="fa-solid fa-circle-check membershipChecks"></i>
                                {value ? HTMLReactParser(value) : ""}
                              </li>
                            );
                          })}
                        </ul>

                        {authenticated ? (
                          <Link
                            to="/plans/purchase"
                            className="btn btn-primary"
                          >
                            {t("userpage.buyThisPlan")}
                          </Link>
                        ) : (
                          <Link
                            to="/user/jobseekerlogin"
                            className="btn btn-primary "
                            onClick={handleBuyWithoutLogin}
                          >
                            {t("userpage.buyThisPlan")}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div> */}
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default MembershipListing;
