import React, { useEffect, useRef, useState } from "react";
import NavBar from "../element/NavBar";
import Footer from "../element/Footer";
import JobCard from "../element/JobCard";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BaseApi from "../api/BaseApi";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import ScrollToTopButton from "../element/ScrollToTopButton";

const SearchJobPage = () => {
  const { slug } = useParams();
  // const initialKeyword = sessionStorage.getItem("keywordTitle") || "";
  let keyWord = sessionStorage.getItem("keywordTitle");

  const initialKeyword = keyWord || slug || "";

  let initialCategory = useRef(sessionStorage.getItem("catId") || "");

  const initialCountry = sessionStorage.getItem("countryId") || "";
  const [filterItem, setFilterItem] = useState({
    keyword: initialKeyword,
    category_id: initialCategory.current,
    subcategory_id: "",
    country_id: initialCountry,
    location: "",
  });

  const navigate = useNavigate();
  const [jobCount, setJobCount] = useState();
  const [filteredJobCount, setFilteredJobCount] = useState();

  const [searchData, setSearchData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const [categoryData, setCategoryData] = useState([]);
  const [searchButton, setSearchButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subCatData, setSubCatData] = useState([]);
  const [searchByCatId, setSearchByCatId] = useState(false);
  var searchByCategory = useRef(false);

  // console.log(initialCategory);
  const [t, i18n] = useTranslation("global");
  const [itemSearched, setItemSearched] = useState([]);
  const getData = async () => {
    // console.log("main get data function se aaya hu");
    try {
      // setLoading(true);
      let response;

      if (initialKeyword) {
        console.log("from keyword");
        // If a keyword is available in the slug, make the API request with the keyword directly.
        response = await axios.post(BaseApi + "/job/listing?page=1", {
          keyword: initialKeyword,
        });
      } else if (initialCategory.current) {
        fetchJobListingsByCategory();
        console.log("from category");
        // setSearchByCatId(true);
        searchByCategory.current = true;
        console.log(searchByCategory, "from get");
        response = await axios.post(BaseApi + "/job/listing?page=1", {
          category_id: initialCategory.current,
        });
      } else if (initialCountry) {
        console.log("from country");
        // fetchJobListingsByCategory();
        response = await axios.post(BaseApi + "/job/listing?page=1", {
          country_id: initialCountry,
        });
      } else {
        console.log("from normal");
        // If no keyword is available, fetch all job listings.
        response = await axios.post(BaseApi + "/job/listing?page=1");
      }

      setCategoryList(response.data.response.category);
      setCategoryData(response.data.response.jobs);
      setCountryList(response.data.response.countryList);
      setJobCount(response.data.response.jobCount);
      setFilteredJobCount(response.data.response.filterJobCount);
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.log("Error fetching list of categories");
    }
  };
  const tokenKey = Cookies.get("tokenClient");
  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  const mapKey = Cookies.get("mapKey");

  const [hoverColor, setHoverColor] = useState(false);

  const handleMouseEnter = () => {
    setHoverColor(true);
  };

  const handleMouseLeave = () => {
    setHoverColor(false);
  };

  const [clearColor, setClearColor] = useState(false);

  const handleClearMouseEnter = () => {
    setClearColor(true);
  };

  const handleClearMouseLeave = () => {
    setClearColor(false);
  };

  const [hoverPrevColor, setHoverPrevColor] = useState(false);
  const [hoverNextColor, setHoverNextColor] = useState(false);

  const handlePrevEnter = () => {
    setHoverPrevColor(true);
    // console.log(hoverColor)
  };

  const handlePrevLeave = () => {
    setHoverPrevColor(false);
  };
  const handleNextEnter = () => {
    setHoverNextColor(true);
    // console.log(hoverColor)
  };

  const handleNextLeave = () => {
    setHoverNextColor(false);
  };

  var catFetchedFromHome = useRef(false);

  useEffect(() => {
    // if(!tokenKey){
    //   navigate("/user/jobseekerlogin");
    // }else{
    getData();
    if (initialCategory.current) {
      fetchJobListingsByCategory(initialCategory.current);
    }
    // }
  }, [initialCategory.current]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    // Check if the category_id is being changed
    setFilterItem((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
    if (name === "category_id") {
      // Call the API with the selected category_id
      fetchJobListingsByCategory(value);
    }
    if (filterItem.category_id === "Any Category") {
      // Include the category_id in the API request
      setFilterItem({ ...filterItem, category_id: "" }); // Convert to the desired data type if needed
    }
    console.log(filterItem);
  };

  let searchJobCount = useRef(0);
  const handleClick = async (e) => {
    e.preventDefault();
    if (
      filterItem.keyword === "" &&
      filterItem.category_id === "" &&
      filterItem.country_id === "" &&
      filterItem.location === ""
    ) {
      return;
    }
    sessionStorage.setItem("jobSearched", "1");
    setSearchButton(true);
    setLoading(true);
    try {
      const response = await axios.post(
        BaseApi + "/job/listing?page=1",
        filterItem
      );
      setSearchData(response.data.response.jobs);
      // setCategoryData(response.data.response.jobs);
      searchJobCount.current = response.data.response.filterJobCount;
      setFilteredJobCount(response.data.response.filterJobCount);
      setLoading(false);
      console.log("Search filter data sent successfully");
    } catch (error) {
      setLoading(false);
      console.log("Couldn't send the search filter data!");
    }
  };

  const fetchJobListingsByCategory = async (categoryId) => {
    // setLoading(true);
    try {
      const response = await axios.post(
        BaseApi + `/categories/getSubCategory/${categoryId}`
      );
      setSubCatData(response.data.response);
      // setLoading(false);
      console.log(subCatData);
    } catch (error) {
      // setLoading(false);
      console.log("Couldn't get sub category data at listing page");
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setSearchButton(false);
    setSearchData([]);
    setSubCatData([]);
    setFilterItem({
      keyword: "",
      category_id: "",
      subcategory_id: "",
      country_id: "",
      location: "",
    });

    if (searchByCatId) {
      console.log("here");
      sessionStorage.clear();
      initialCategory.current = "";
      setSearchByCatId(false);
      searchByCategory.current = false;
      setSearchButton(false);
      getData();

      console.log(searchByCategory.current, "searchbycategory");
      console.log(searchButton, "searchbutton");
      console.log(searchByCatId);
    }
  };

  // useEffect(() => {

  // }, []);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 10;

  // Get current jobs to display based on pagination
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = searchButton
    ? searchData.slice(indexOfFirstJob, indexOfLastJob)
    : categoryData.slice(indexOfFirstJob, indexOfLastJob);

  // Function to handle pagination page change
  // const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const [autocompleteService, setAutocompleteService] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    // Load Google Maps AutocompleteService after component mounts
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=places`;
    script.onload = () => {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
      console.log(autocompleteService);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleLocationChange = (e) => {
    const { value } = e.target;
    setSuggestionTaken(false);
    if (value == "") {
      setSuggestionTaken(true);
    }

    setFilterItem((prevFilter) => ({
      ...prevFilter,
      location: value,
    }));

    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ["(cities)"], // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === "OK" && predictions) {
            setSuggestions(
              predictions.map((prediction) => prediction.description)
            );
          } else {
            setSuggestions([]);
          }
        }
      );
    }
    if (filterItem.location === "") {
      setSuggestions([]);
    }
  };
  const [suggestionTaken, setSuggestionTaken] = useState(false);

  const handleSuggestionClick = (suggestion) => {
    // Update the input value with the clicked suggestion
    handleLocationChange({ target: { name: "location", value: suggestion } });

    setSuggestionTaken(true);
    // Clear the suggestions
    setSuggestions([]);
    console.log(filterItem);
  };

  const [jobs, setJobs] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  let count = 2;

  const handlePageChange = (pageNumber) => {
    // console.log("Current page:", pageNumber);
    setCurrentPage(pageNumber);
    fetchMoreJobs(pageNumber);
  };

  useEffect(() => {
      const handleScroll = () => {
        if (
          window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight
        ) {
          console.log(
            searchByCategory.current,
            "searchbycategory from useeffect"
          );
          console.log(searchButton, "searchbutton from useeffect");
  
          if (searchByCategory.current === false && searchButton === false) {
            fetchMoreJobs(currentPage + 1); // Fetch more jobs when user reaches bottom
            console.log(searchByCategory, "from useeffect");
            setSearchByCatId(false);
          }
          if (searchButton === true) {
            fetchMoreJobsOnSearch(currentPage + 1);
            console.log("fom fetchMoreJobsOnSearch");
          }
          if (searchByCategory.current === true && searchButton === false) {
            console.log("only category data should come");
            setSearchByCatId(true);
          }
  
          if (catFetchedFromHome.current) {
            fetchMoreJobsFromHome(currentPage + 1);
            console.log("from fetchMoreJobsOnSearch from catfetchedfromhome");
          }
        }
      };
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    
    
  }, [currentPage]); // Listen to changes in currentPage to fetch more jobs

  const fetchMoreJobs = async (pageNumber) => {
    if (!hasMore || loading || searchByCatId) return;
    // setLoading(true);

    console.log("from fetch more");

    try {
      const response = await axios.post(
        BaseApi + `/job/listing?page=${pageNumber}`
      );

      // Scroll up the page a little bit
      window.scrollTo({
        top: window.scrollY - 100, // Adjust the value as needed
        behavior: "smooth", // You can use "auto" for instant scroll
      });
      if(searchButton){
        setJobs((prevJobs) => [...prevJobs, ...response.data.response.jobs]);
        setSearchData((prevJobs) => [
          ...prevJobs,
          ...response.data.response.jobs,
        ])
      }

      setJobs((prevJobs) => [...prevJobs, ...response.data.response.jobs]);
      setCategoryData((prevJobs) => [
        ...prevJobs,
        ...response.data.response.jobs,
      ]);

      setCurrentPage(pageNumber);
      setHasMore(response.data.response.jobs.length > 0);
      // setJobs((prevJobs) => [...prevJobs, ...response.data.response.jobs]);
      // setCurrentPage(pageNumber);
      // setHasMore(response.data.response.jobs.length > 0);
    } catch (error) {
      console.log("Error fetching more jobs:", error);
    }

    // setLoading(false);
  };

  const fetchMoreJobsOnSearch = async (pageNumber) => {
    if (!searchButton) return;
    // setLoading(true);

    console.log("from fetch more by search button");

    try {
      let response;

      if (filterItem.keyword) {
        console.log("from keyword");
        // If a keyword is available in the slug, make the API request with the keyword directly.
        response = await axios.post(
          BaseApi + `/job/listing?page=${pageNumber}`,
          {
            keyword: filterItem.keyword,
          }
        );
      } else if (filterItem.category_id) {
        fetchJobListingsByCategory();
        console.log("from category");
        // setSearchByCatId(true);
        // searchByCategory = true;
        // console.log(searchByCategory, "from get");
        response = await axios.post(
          BaseApi + `/job/listing?page=${pageNumber}`,
          {
            category_id: filterItem.category_id,
          }
        );
      } else if (filterItem.country_id) {
        console.log("from country");
        // fetchJobListingsByCategory();
        response = await axios.post(
          BaseApi + `/job/listing?page=${pageNumber}`,
          {
            country_id: filterItem.country_id,
          }
        );
      } else if (filterItem.location) {
        console.log("from location");
        response = await axios.post(
          BaseApi + `/job/listing?page=${pageNumber}`,
          {
            location: filterItem.location,
          }
        );
      } else {
        console.log("from normal");
        // If no keyword is available, fetch all job listings.
        response = await axios.post(
          BaseApi + `/job/listing?page=${pageNumber}`
        );
      }

      // Scroll up the page a little bit
      window.scrollTo({
        top: window.scrollY - 100, // Adjust the value as needed
        behavior: "smooth", // You can use "auto" for instant scroll
      });

      setJobs((prevJobs) => [...prevJobs, ...response.data.response.jobs]);
      setSearchData((prevJobs) => [
        ...prevJobs,
        ...response.data.response.jobs,
      ]);
      setFilteredJobCount(response.data.response.filterJobCount);

      setCurrentPage(pageNumber);
    } catch (error) {
      console.log("Error fetching more jobs:", error);
    }
  };

  const fetchMoreJobsFromHome = async (pageNumber) => {
    console.log("from fetch more by search button");

    try {
      let response;

      if (initialCategory) {
        fetchJobListingsByCategory();
        console.log("from category");
        // setSearchByCatId(true);
        // searchByCategory = true;
        // console.log(searchByCategory, "from get");
        response = await axios.post(
          BaseApi + `/job/listing?page=${pageNumber}`,
          {
            category_id: filterItem.category_id || initialCategory,
          }
        );
      }

      // Scroll up the page a little bit
      window.scrollTo({
        top: window.scrollY - 100, // Adjust the value as needed
        behavior: "smooth", // You can use "auto" for instant scroll
      });

      setJobs((prevJobs) => [...prevJobs, ...response.data.response.jobs]);
      setCategoryData((prevJobs) => [
        ...prevJobs,
        ...response.data.response.jobs,
      ]);

      setCurrentPage(pageNumber);
    } catch (error) {
      console.log("Error fetching more jobs:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      sessionStorage.clear();
    }, 7000);

    setTimeout(() => {
      setLoading(false);
    }, 5000);

    if (initialCategory) {
      catFetchedFromHome.current = true;
    }
  }, []);

  const [hoverUploadCVColor, setHoverUploadCVColor] = useState(false);
  const [hoverCreateAlertColor, setHoverCreateAlertColor] = useState(false);

  const handleUploadCVMouseEnter = () => {
    setHoverUploadCVColor(true);
  };

  const handleUploadCVMouseLeave = () => {
    setHoverUploadCVColor(false);
  };

  return (
    <>
      <NavBar />
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <div className="searchPageParentBox">
            <div className="SJPSection2 container">
              <div className="jobActionButtonsBx">
                <div className="jobsAction1">
                  {tokenKey  && (
                    <Link
                      to="/alerts/add"
                      className="jobActionButtons"
                      style={{
                        backgroundColor: hoverCreateAlertColor
                          ? secondaryColor
                          : primaryColor,
                        border: hoverCreateAlertColor
                          ? secondaryColor
                          : primaryColor,
                      }}
                    >
                      Create Job Alert
                    </Link>
                  )}
                  {!tokenKey && (
                    <Link
                      to="/user/jobseekerlogin"
                      className="jobActionButtons"
                      style={{
                        backgroundColor: hoverCreateAlertColor
                          ? secondaryColor
                          : primaryColor,
                        border: hoverCreateAlertColor
                          ? secondaryColor
                          : primaryColor,
                      }}
                    >
                      Create Job Alert
                    </Link>
                  )}
                </div>
                {tokenKey && (
                  <Link
                    to="/candidates/addcvdocuments"
                    // type="button"
                    className="jobActionButtons"
                    style={{
                      backgroundColor: hoverCreateAlertColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverCreateAlertColor
                        ? secondaryColor
                        : primaryColor,
                    }}
                    onMouseEnter={handleUploadCVMouseEnter}
                    onMouseLeave={handleUploadCVMouseLeave}
                  >
                    {t("userpage.uploadCVButton")}
                  </Link>
                )}
                {!tokenKey  && (
                  <Link
                    to="/user/jobseekerlogin"
                    // type="button"
                    className="jobActionButtons"
                    style={{
                      backgroundColor: hoverCreateAlertColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverCreateAlertColor
                        ? secondaryColor
                        : primaryColor,
                    }}
                    onMouseEnter={handleUploadCVMouseEnter}
                    onMouseLeave={handleUploadCVMouseLeave}
                  >
                    {t("userpage.uploadCVButton")}
                  </Link>
                )}
              </div>
              <div className="blogPagination searchPagePagination">
                <p className="text-muted paginationDetailForSearch">
                  Number of Jobs: 1 -{" "}
                  {searchButton ? searchData.length : categoryData.length} of{" "}
                  {searchButton ? filteredJobCount : jobCount}
                </p>
                <div className="blogPaginationButtons">
                  <button
                    className="navButton1"
                    style={{
                      backgroundColor: hoverNextColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverNextColor ? secondaryColor : primaryColor,
                    }}
                    onMouseEnter={handleNextEnter}
                    onMouseLeave={handleNextLeave}
                    disabled={searchButton ? searchData.length >= searchJobCount.current : categoryData.length >= jobCount}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Load More
                  </button>
                </div>
              </div>
              <div className="FBitem">
                <div className="row">
                  <div className="col-md-3">
                    <div className="SJPSection1">
                      <form>
                        <div className="formItems">
                          <div className="searchItems me-2">
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              value={filterItem.keyword}
                              name="keyword"
                              placeholder={t("searchJobPage.keyword")}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="searchItems me-2">
                            <select
                              className="form-select text-muted"
                              aria-label="Default select example"
                              value={filterItem.category_id}
                              name="category_id"
                              onChange={handleChange}
                            >
                              <option value={""}>
                                {t("searchJobPage.anyCategory")}
                              </option>
                              {categoryList.map((i, id) => {
                                return (
                                  <option key={i.id} value={i.id}>
                                    {i.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="searchItems me-2">
                            <select
                              className="form-select text-muted"
                              aria-label="Default select example"
                              value={filterItem.subcategory_id}
                              name="subcategory_id"
                              onChange={handleChange}
                            >
                              <option value={""}>
                                {t("searchJobPage.subCategory")}
                              </option>
                              {subCatData.map((i, id) => {
                                return (
                                  <option key={i.id} value={i.id}>
                                    {i.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="searchItems me-2">
                            <select
                              className="form-select text-muted"
                              aria-label="Default select example"
                              value={filterItem.country_id}
                              name="country_id"
                              onChange={handleChange}
                            >
                              <option value={""}>
                                {t("searchJobPage.SelectCountry")}
                              </option>
                              {countryList.map((i, id) => {
                                return (
                                  <option key={i.id} value={i.id}>
                                    {i.location_country}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="searchItems me-2">
                            <input
                              type="text"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder={t("searchJobPage.enterLocation")}
                              name="location"
                              value={filterItem.location}
                              onChange={handleLocationChange}
                            />
                            {suggestions.length > 0 && (
                              <div
                                className="suggestionsSmall"
                                style={{
                                  display: suggestionTaken ? "none" : "",
                                }}
                              >
                                <ul className="locationDropdown">
                                  {suggestions.map((suggestion, index) => (
                                    <div
                                      key={index}
                                      className="suggestion-item"
                                    >
                                      <li
                                        onClick={() =>
                                          handleSuggestionClick(suggestion)
                                        }
                                      >
                                        <div className="eachLocation">
                                          <div className="locationIcon">
                                            <LocationOnIcon fontSize="small" />
                                          </div>{" "}
                                          <div className="locationSuggestion">
                                            {suggestion}
                                          </div>
                                        </div>
                                      </li>
                                    </div>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                          <button
                            type="submit"
                            className="searchItems me-2 btn"
                            onClick={handleClick}
                            style={{
                              backgroundColor: `${
                                secondaryColor &&
                                (hoverColor ? secondaryColor : primaryColor)
                              }`,
                            }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                          >
                            {t("searchJobPage.findJobs")}
                          </button>
                          <button
                            type="submit"
                            className="searchItems me-2 btn searchButtons"
                            onClick={handleReset}
                            style={{
                              backgroundColor: `${
                                secondaryColor &&
                                (clearColor ? secondaryColor : primaryColor)
                              }`,
                            }}
                            onMouseEnter={handleClearMouseEnter}
                            onMouseLeave={handleClearMouseLeave}
                          >
                            {t("searchJobPage.clear")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      {searchButton ? (
                        searchData.length > 0 ? (
                          searchData.map((i) => (
                            <div className="col-md-6 col-lg-6">
                              <JobCard
                                title={i.title}
                                min_salary={i.min_salary}
                                max_salary={i.max_salary}
                                min_exp={i.min_exp}
                                created={i.created}
                                logo={i.logo}
                                company_name={i.company_name}
                                job_type={i.job_type}
                                job_city={i.job_city}
                                slug={i.slug}
                                cat_slug={i.cat_slug}
                                desc={i.description}
                                salary_details={i.salarydetails}
                              />
                            </div>
                          ))
                        ) : (
                          <div className="col-12">
                            {searchData.length === 0 && (
                              <>
                                <h3 className="text-center">
                                  {t("searchJobPage.noJobsTxt1")}{" "}
                                </h3>
                                <h6 className="text-muted text-center mb-5 mt-3">
                                  {t("searchJobPage.noJobsTxt2")}
                                </h6>
                              </>
                            )}
                          </div>
                        )
                      ) : categoryData.length > 0 ? (
                        categoryData.map((i) => (
                          <div className="col-md-6 col-lg-6">
                            <JobCard
                              title={i.title}
                              min_salary={i.min_salary}
                              max_salary={i.max_salary}
                              min_exp={i.min_exp}
                              created={i.created}
                              logo={i.logo}
                              company_name={i.company_name}
                              job_type={i.job_type}
                              slug={i.slug}
                              cat_slug={i.cat_slug}
                              job_city={i.job_city}
                              desc={i.description}
                              salary_details={i.salarydetails}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="col-12">
                          {categoryData.length === 0 && (
                            <>
                              <h3 className="text-center">
                                {t("searchJobPage.noJobsTxt1")}{" "}
                              </h3>
                              <h6 className="text-muted text-center mb-5 mt-3">
                                {t("searchJobPage.noJobsTxt2")}{" "}
                              </h6>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Custom Pagination */}
              <div className="blogPagination searchPagePagination">
                <p className="text-muted paginationDetailForSearch">
                  Number of Jobs: 1 -{" "}
                  {searchButton ? searchData.length : categoryData.length} of{" "}
                  {searchButton ? filteredJobCount : jobCount}
                </p>
                <div className="blogPaginationButtons">
                  <button
                    className="navButton1"
                    style={{
                      backgroundColor: hoverNextColor
                        ? secondaryColor
                        : primaryColor,
                      border: hoverNextColor ? secondaryColor : primaryColor,
                    }}
                    onMouseEnter={handleNextEnter}
                    onMouseLeave={handleNextLeave}
                    disabled={searchButton ? searchData.length >= searchJobCount.current : categoryData.length >= jobCount}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Load More
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ScrollToTopButton />

          <Footer />
        </>
      )}
    </>
  );
};

export default SearchJobPage;
