import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  EmailShareButton,
  InstapaperShareButton,
  TelegramShareButton,
} from "react-share";
import {
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
  PinterestIcon,
  InstapaperIcon,
  TelegramIcon,
} from "react-share";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Typography } from "@mui/material";
import { useState } from "react";
import HTMLReactParser from "html-react-parser";

const JobCardNew = (props) => {
  let curr = Cookies.get("curr");

  const [open, setOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null); // Track the selected payment

  const handleOpen = () => {
    // console.log("Clicked payment:", plan); // Add this line
    // setSelectedPayment(plan);
    setOpen(true);
  };
  const [t, i18n] = useTranslation("global");

  const handleClose = () => {
    // setSelectedPayment(null);
    setOpen(false);
  };
  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%", // Adjusted width for mobile responsiveness
    maxWidth: "400px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <div className="SportsCard">
        <div className="d-flex justify-content-around ">
          <div className="jobCardNewLeftSection">
            {props.logo ? (
              <img src={props.logo} width={50} height={50} alt="" />
            ) : (
              <img
                src="/Images/featured-job02.jpg"
                width={50}
                height={50}
                alt=""
              />
            )}

            <div
              className="d-flex gap-1 align-items-center"
              style={{ marginTop: "14px", fontSize: "14px" }}
            >
              <i class="fa-solid fa-location-dot"></i>
              <p className="mb-0">
                {props.location && props.location.substring(0, 10)}
              </p>
            </div>
          </div>
          <div className="SportsTitle">
            <div className="upperSectionJobCardNew">
              <h6>{props.company_name}</h6>
              <Link
                to={`/jobdescription/${props.slug}/${props.cat_slug}`}
                className="text-black"
              >
                <h4>{props.job_title}</h4>
              </Link>
              <div className="sharebtn" onClick={() => handleOpen()}>
                <img src="/Images/share.png" alt="" />
              </div>

              <div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{ ...style, width: 700 }}>
                    <Button
                      onClick={handleClose} // Call handleClose when the button is clicked
                      sx={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                      }} // Position the button
                    >
                      {t("messageForm.close")}
                    </Button>

                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    ></Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 6 }}>
                      <div className="modals ">
                        <div className="modalHead">
                          <h3>{t("jobCardNew.shareNow")}</h3>
                        </div>
                        <div className="modalBody mt-4">
                          <ul className="shareIconsList">
                            <li className="shareIconsButtons">
                              <FacebookShareButton url={props.job_url}>
                                <FacebookIcon
                                  logoFillColor="white"
                                  round={true}
                                ></FacebookIcon>
                              </FacebookShareButton>
                            </li>
                            <li className="shareIconsButtons">
                              <TwitterShareButton url={props.job_url}>
                                <TwitterIcon
                                  logoFillColor="white"
                                  round={true}
                                ></TwitterIcon>
                              </TwitterShareButton>
                            </li>
                            <li className="shareIconsButtons">
                              <WhatsappShareButton url={props.job_url}>
                                <WhatsappIcon
                                  logoFillColor="white"
                                  round={true}
                                ></WhatsappIcon>
                              </WhatsappShareButton>
                            </li>
                            <li className="shareIconsButtons">
                              <LinkedinShareButton url={props.job_url}>
                                <LinkedinIcon
                                  logoFillColor="white"
                                  round={true}
                                ></LinkedinIcon>
                              </LinkedinShareButton>
                            </li>

                            <li className="shareIconsButtons">
                              <EmailShareButton url={props.job_url}>
                                <EmailIcon
                                  logoFillColor="white"
                                  round={true}
                                ></EmailIcon>
                              </EmailShareButton>
                            </li>
                            <li className="shareIconsButtons">
                              <PinterestShareButton url={props.job_url}>
                                <PinterestIcon
                                  logoFillColor="white"
                                  round={true}
                                ></PinterestIcon>
                              </PinterestShareButton>
                            </li>
                            <li className="shareIconsButtons">
                              <InstapaperShareButton url={props.job_url}>
                                <InstapaperIcon
                                  logoFillColor="white"
                                  round={true}
                                ></InstapaperIcon>
                              </InstapaperShareButton>
                            </li>
                            <li className="shareIconsButtons">
                              <TelegramShareButton url={props.job_url}>
                                <TelegramIcon
                                  logoFillColor="white"
                                  round={true}
                                ></TelegramIcon>
                              </TelegramShareButton>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Typography>
                  </Box>
                </Modal>
              </div>

              <div className="ExperienceRequired">
                <h6 className="text-muted">
                  Experience: {props.min_exp} - {props.max_exp} Years
                </h6>
                <p className="mb-0 text-muted">{props.created} days ago</p>
              </div>
            </div>

            <div className="bordereder-uline"></div>
            <div className="Salary">
              <h6 className="text-muted">
              <i class="fa-solid fa-dollar-sign"></i>{" "}{props.salary_details === null ||
                props.salary_details === "" ? (
                  <>
                    {curr}
                    {props.min_salary}-{curr}
                    {props.max_salary}/{t("messageForm.jobCardYear")}
                  </>
                ) : (
                  props.salary_details
                )}
              </h6>
              <Link to="" className=" btnFull">
              <i class="fa-solid fa-briefcase"></i>{" "}{props.work_type ? props.work_type : "N/A"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobCardNew;
