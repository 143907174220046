import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";
import HTMLReactParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import ProfileCard from "./ProfileCard";
import ApiKey from "../api/ApiKey";

const SportsCVListing = () => {
  const [t, i18n] = useTranslation("global");
  const [jobseekersData, setJobseekersData] = useState([]);
  const tokenKey = Cookies.get("tokenClient");
  const [loading, setLoading] = useState(false);

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");

  const [hoverPrevColor, setHoverPrevColor] = useState(false);
  const [hoverNextColor, setHoverNextColor] = useState(false);

  const handlePrevEnter = () => {
    setHoverPrevColor(true);
    // console.log(hoverColor)
  };

  const handlePrevLeave = () => {
    setHoverPrevColor(false);
  };
  const handleNextEnter = () => {
    setHoverNextColor(true);
    // console.log(hoverColor)
  };

  const handleNextLeave = () => {
    setHoverNextColor(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 20;

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(BaseApi + `/sportscvlisting`, {
        headers: {
          "Content-Type": "application/json",
          key: ApiKey,
          token: tokenKey,
        },
      });
      setLoading(false);

      setJobseekersData(response.data.response.jobseekers);
    } catch (error) {
      setLoading(false);
      console.log("Cannot get home page data!");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Calculate pagination data
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = jobseekersData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  // Change page
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <NavBar />
      {/* {loading ? (
        <>
          <div className="loader-container"></div>
        </>
      ) : (
        <> */}
      <div className="privacyPolicy membershipListing">
        <div className="text-center PPSection1">
          <h1 className="">Sports CVs</h1>
          <h6 className="text-muted fw-normal">
            {" "}
            <Link to="/" style={{ color: "grey" }}>
              {t("navHeaders.home")}
            </Link>{" "}
            / Sports CVs
          </h6>
        </div>

        <div className="container">
          <div className="jobActionButtonsBx mb-5">
            <div className="jobsAction1">
              {tokenKey ? (
                <Link to="/alerts/add" className="jobActionButtons">
                  Create Job Alert
                </Link>
              ) : (
                <Link to="/user/jobseekerlogin" className="jobActionButtons">
                  Create Job Alert
                </Link>
              )}
            </div>
            {tokenKey ? (
              <Link
                to="/candidates/addcvdocuments"
                // type="button"
                className="jobActionButtons"
              >
                {t("userpage.uploadCVButton")}
              </Link>
            ) : (
              <Link
                to="/user/jobseekerlogin"
                // type="button"
                className="jobActionButtons"
              >
                {t("userpage.uploadCVButton")}
              </Link>
            )}
          </div>
          <div className="row">
            {currentRecords.map((i) => {
              return (
                <div className="col-md-4 col-lg-4">
                  <ProfileCard
                    first_name={i.first_name}
                    last_name={i.last_name}
                    profile_image={i.profile_image}
                    location={i.location}
                    exp_salary={i.exp_salary}
                    slug={i.slug}
                    role={i.role}
                    old_docs={i.showOldDocs}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {/* Pagination */}

        <div className="blogPagination">
          <p className="text-muted paginationDetail">
            {t("pagination.NoofRecords")} {indexOfFirstRecord + 1}-
            {Math.min(indexOfLastRecord, jobseekersData.length)} of{" "}
            {jobseekersData ? jobseekersData.length : jobseekersData.length}
          </p>
          <div className="blogPaginationButtons">
            <button
              className="navButton1"
              style={{
                backgroundColor: hoverPrevColor ? secondaryColor : primaryColor,
                border: hoverPrevColor ? secondaryColor : primaryColor,
              }}
              onMouseEnter={handlePrevEnter}
              onMouseLeave={handlePrevLeave}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              {t("pagination.Prev")}
            </button>
            <button
              className="navButton1"
              style={{
                backgroundColor: hoverNextColor ? secondaryColor : primaryColor,
                border: hoverNextColor ? secondaryColor : primaryColor,
              }}
              onMouseEnter={handleNextEnter}
              onMouseLeave={handleNextLeave}
              disabled={
                jobseekersData
                  ? indexOfLastRecord >= jobseekersData.length
                  : indexOfLastRecord >= jobseekersData.length
              }
              onClick={() => handlePageChange(currentPage + 1)}
            >
              {t("pagination.Next")}
            </button>
          </div>
        </div>
      </div>
      {/* </>
      )} */}
      <Footer />
    </>
  );
};

export default SportsCVListing;
