import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import APNavBar from "../Elements/APNavBar";
import APSidebar from "../APSidebar/APSidebar";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import axios from "axios";
import BaseApi from "../../api/BaseApi";
import ApiKey from "../../api/ApiKey";
import Swal from "sweetalert2";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import Tooltip from "@mui/material/Tooltip";
// import AddIcon from "@mui/icons-material/Add";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Cookies from "js-cookie";
import { Button, IconButton } from "@mui/material";
import HTMLReactParser from "html-react-parser";
import APFooter from "../Elements/APFooter";
import { DataGrid } from "@mui/x-data-grid";

const NewJobList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [checkedData, setCheckedData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const tokenKey = Cookies.get("token");
  const adminID = Cookies.get("adminID");

  const [currentPage, setCurrentPage] = useState(1);
  // const [dataPerPage, setDataPerPage] = useState([]);
  const itemsPerPage = 20;
  const [open, setOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  // const [selectedPlan, setSelectedPlan] = useState(null);
  const handleOpen = (plan) => {
    setSelectedPayment(plan);
    setOpen(true);
    console.log(plan)
  };

  const handleClose = () => {
    setSelectedPayment(null);
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%", // Adjusted width for mobile responsiveness
    maxWidth: "400px",
    // maxHeight: "800px",
    backgroundColor: "white", // Use a valid color or background image here
    border: "2px solid #000",
    boxShadow: "0 0 24px rgba(0, 0, 0, 0.2)", // Adjust the shadow values as needed
    padding: "4px", // Adjust padding as needed
    // overflowY: "scroll", // Add a vertical scrollbar
  };

  const handleActivate = async (slug) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Activate Job?",
        text: "Do you want to Activate this Job?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        // setLoading(true);
        const response = await axios.post(
          BaseApi + `/admin/job/admin_activate/${slug}`,
          null, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          }
        );
        // setLoading(false);
        if (response.data.status === 200) {
          Swal.fire({
            title: "Job Activated successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
        } else {
          Swal.fire({
            title: response.data.message,
            icon: "error",
            confirmButtonText: "Close",
          });
        }
        getData();
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed. Please try after some time!",
        text: "Could not Activate Job",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Couldn't activate the record!", error.message);
    }
  };
  const handleDeactivate = async (slug) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Deactivate Job",
        text: "Do you want to Deactivate this Job?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        // setLoading(true);
        const response = await axios.post(
          BaseApi + `/admin/job/admin_deactivate/${slug}`,
          null, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          }
        );
        // setLoading(false);
        if (response.data.status === 200) {
          Swal.fire({
            title: "Job Deactivated successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
        } else {
          Swal.fire({
            title: response.data.message,
            icon: "error",
            confirmButtonText: "Close",
          });
        }
        getData();
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed. Please try after some time!",
        text: "Could not Deactivate Job",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Couldn't deactivate the record!", error.message);
    }
  };

  const handleDelete = async (slug) => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Delete Job?",
        text: "Do you want to Delete this Job?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        // setLoading(true);
        const response = await axios.post(
          BaseApi + `/admin/job/admin_delete/${slug}`,
          null, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          }
        );
        // setLoading(false);
        if (response.data.status === 200) {
          Swal.fire({
            title: "Job deleted successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
        } else {
          Swal.fire({
            title: response.data.message,
            icon: "error",
            confirmButtonText: "Close",
          });
        }
        getData();
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed. Please try after some time!",
        text: "Could not Delete job",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Couldn't delete the record!", error.message);
    }
  };

  const [selectAll, setSelectAll] = useState(true);

  const handleMultipleDeactivate = async () => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Deactivate Jobs?",
        text: "Do you want to Deactivate these Jobs?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        const ids = selectedIds.join(",");
        // console.log(ids);
        // setLoading(true);
        const response = await axios.post(
          BaseApi + "/admin/job/index",
          {
            idList: ids,
            action: "deactivate",
          }, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          }
        );

        setSelectAll(true);
        setCheckedData("");
        // setLoading(false);
        if (response.data.status === 200) {
          Swal.fire({
            title: "Jobs Deactivated successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
        } else {
          Swal.fire({
            title: "Couldn't Deactivate!",
            icon: "error",
            confirmButtonText: "Close",
          });
        }
        getData();
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed. Please try after some time!",
        text: "Could not Deactivate Jobs",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Couldn't deactivate the record!", error.message);
    }
  };
  const handleMultipleActivate = async () => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Activate Jobs?",
        text: "Do you want to Activate these Jobs?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        const ids = selectedIds.join(",");
        // console.log(ids);
        // setLoading(true);
        const response = await axios.post(
          BaseApi + "/admin/job/index",
          {
            idList: ids,
            action: "activate",
          }, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          }
        );

        setSelectAll(true);
        setCheckedData("");
        // setLoading(false);
        if (response.data.status === 200) {
          Swal.fire({
            title: "Jobs Activated successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
        } else {
          Swal.fire({
            title: "Couldn't Activate!",
            icon: "error",
            confirmButtonText: "Close",
          });
        }
        getData();
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed. Please try after some time!",
        text: "Could not Activate Jobs",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Couldn't activate the record!", error.message);
    }
  };
  const handleMultipleDelete = async () => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Delete Jobs?",
        text: "Do you want to Delete these Jobs?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        const ids = selectedIds.join(",");
        // console.log(ids);
        // setLoading(true);
        const response = await axios.post(
          BaseApi + "/admin/job/index",
          {
            idList: ids,
            action: "delete",
          }, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
              adminid: adminID,
            },
          }
        );

        setSelectAll(true);
        setCheckedData("");
        // setLoading(false);
        if (response.data.status === 200) {
          Swal.fire({
            title: "Jobs Deleted successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
        } else {
          Swal.fire({
            title: "Couldn't Delete!",
            icon: "error",
            confirmButtonText: "Close",
          });
        }
        getData();
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed. Please try after some time!",
        text: "Could not Delete Jobs",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Couldn't Delete the record!", error.message);
    }
  };

  const [searchData, setSearchData] = useState("");
  const [searchKey, setSearchKey] = useState({
    searchQuery: "",
  });

  const [isSearchAvailable, setIsSearchAvailable] = useState(false);

  //   const handleSearch = async (e) => {
  //     e.preventDefault();
  //     try {
  //       const response = await axios.post(
  //         BaseApi + "/admin/job/index/searchquery",
  //         searchKey,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             key: ApiKey,
  //             token: tokenKey,
  //             adminid: adminID,
  //           },
  //         }
  //       );
  //       setIsSearchAvailable(true);
  //       setSearchData(response.data.response);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };

  const [totalJobsCount, setTotalJobsCount] = useState();

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + "/admin/job/index?page=1",
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
          },
        }
      );
      setLoading(false);
      setListData(response.data.response.jobs);
      setTotalJobsCount(response.data.response.totalJobs);
      // totalJobs = response.data.response.totalJobs;
      //   console.log("from getdata");
      // setListData((prevListData) => [...prevListData, ...response.data.response]);

      // console.log("list",listData);
    } catch (error) {
      setLoading(false);
      console.log("Cannot get plans data at APmanageplans");
    }
  };

  const fetchMoreJobs = async (e, pagenumber) => {
    e.preventDefault();
    // setCurrentPage(currentPage + 1)
    try {
      const response = await axios.post(
        BaseApi + "/admin/job/index?page=" + pagenumber,
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
            adminid: adminID,
          },
        }
      );

      setListData((prevListData) => [
        ...prevListData,
        ...response.data.response.jobs,
      ]);
      setCurrentPage(pagenumber);
      console.log(listData, "from next");
      // setHasMore(response.data.response.jobs.length > 0);
    } catch (error) {
      console.log("Error fetching more jobs:", error);
    }
  };

  const sortAndFilterData = (array, key, direction, query) => {
    const sortedAndFilteredArray = [...array]
      .filter(
        (item) =>
          item.emp_name.toLowerCase().includes(query) ||
          item.title.toLowerCase().includes(query) ||
          item.company_name.toLowerCase().includes(query) ||
          item.created.toLowerCase().includes(query)
      )
      .sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === "ascending" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    return sortedAndFilteredArray;
  };
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });
  const [symbol, setSymbol] = useState("🔺");

  const sortedAndFilteredData = sortAndFilterData(
    listData,
    sortConfig.key,
    sortConfig.direction,
    searchQuery
  );

  const filteredData = listData.filter((item) => {
    const searchString = searchQuery.toLowerCase();
    return (
      item.emp_name.toLowerCase().includes(searchString) ||
      item.title.toLowerCase().includes(searchString) ||
      item.company_name.toLowerCase().includes(searchString) ||
      item.created.toLowerCase().includes(searchString)
    );
  });

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = sortedAndFilteredData.slice(
  //   indexOfFirstItem,
  //   indexOfLastItem
  // );

  const totalPages = Math.ceil(sortedAndFilteredData.length / itemsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    if (!tokenKey) {
      navigate("/admin");
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  // Update the slice of data to display based on currentPage
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedAndFilteredData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  useEffect(() => {
    setCurrentPage(1); // Reset currentPage to 1 when searchQuery changes
  }, [searchQuery]);

  const handleSearchChange = (e) => {
    const newQuery = e.target.value.toLowerCase();
    setSearchQuery(newQuery);
  };

  const getWorkType = (id) => {
    if (id === 1) {
      return "Full Time";
    }
    if (id === 2) {
      return "Part Time";
    }
    if (id === 3) {
      return "Casual";
    }
    if (id === 4) {
      return "Seasonal";
    }
    if (id === 5) {
      return "Fixed Term";
    }
  };

  const [selectedIds, setSelectedIds] = useState([]);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [skills, setSkills] = useState(null);
  const [filteredSkills, setFilteredSkills] = useState([]);

  const columns = [
    {
      field: "emp_name",
      headerName: "Employer Name",
      width: 200,
      headerClassName: "redText",
    },
    {
      field: "title",
      headerName: "Job Title",
      width: 200,
      headerClassName: "redText",
    },
    {
      field: "company_name",
      headerName: "Company Name",
      width: 200,
      headerClassName: "redText",
    },
    {
      field: "location",
      headerName: "Location",
      width: 180,
      headerClassName: "redText",
    },
    {
      field: "cat_name",
      headerName: "Category",
      width: 180,
      headerClassName: "redText",
    },
    {
      field: "jobseeker_count",
      headerName: "Jobseekers",
      width: 100,
      headerClassName: "redText",
    },

    {
      field: "created",
      headerName: "Created at",
      width: 180,
      headerClassName: "redText",
    },

    {
      field: "action",
      headerName: "Action",
      headerClassName: "redText",
      width: 293,
      renderCell: (params) => (
        // <div className="process__actionnn" style={{ margin: "10px 0px" }}>
        //   {params.row.status === 1 ? (
        //     <button
        //       className="btn btn-success btn-successss"
        //       // onClick={() => handleDeactivate(params.row.slug)}
        //       onClick={(e) => {
        //         handleDeactivate(params.row.slug);
        //         e.stopPropagation(); // Prevents event bubbling
        //       }}
        //       title="Activate"
        //     >
        //       <i class="fa-solid fa-check"></i>
        //     </button>
        //   ) : (
        //     <button
        //       className="btn btn-danger btn-deactivateee"
        //       // onClick={() => handleActivate(params.row.slug)}
        //       onClick={(e) => {
        //         handleActivate(params.row.slug);
        //         e.stopPropagation(); // Prevents event bubbling
        //       }}
        //       title="Deactivate"
        //     >
        //       <i class="fa-solid fa-ban"></i>
        //     </button>
        //   )}

        //   <Link
        //     href={`qualificationlist/edit/${params.row.slug}`}
        //     className="btn  btn-warning btn-Edittt"
        //     onClick={(e) => {
        //       e.stopPropagation(); // Prevents event bubbling
        //     }}
        //   >
        //     <i class="fa-solid fa-square-pen"></i>
        //   </Link>
        //   <button
        //     className="btn  btn-danger btn-Deleteee"
        //     // onClick={() => handleDelete(params.row.slug)}
        //     onClick={(e) => {
        //       handleDelete(params.row.slug);
        //       e.stopPropagation(); // Prevents event bubbling
        //     }}
        //   >
        //     <i class="fa-solid fa-trash-can"></i>
        //   </button>
        // </div>
        <td className="APActionButton">
          {params.row.status === 1 ? (
            <button
              className="btn btn-secondary"
              onClick={() => handleDeactivate(params.row.slug)}
            >
              <Tooltip title="Deactivate">
                <CheckIcon />
              </Tooltip>
            </button>
          ) : (
            <button
              className="btn btn-secondary"
              onClick={() => handleActivate(params.row.slug)}
            >
              <Tooltip title="Activate">
                <BlockIcon />
              </Tooltip>
            </button>
          )}

          <button
            className="btn btn-primary"
            onClick={() => navigate(`/admin/jobs/editjob/${params.row.slug}`)}
          >
            <Tooltip title="Edit">
              <CreateIcon />
            </Tooltip>
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => handleDelete(params.row.slug)}
          >
            <Tooltip title="Delete">
              <DeleteIcon />
            </Tooltip>
          </button>

          <button
            className="btn btn-primary"
            onClick={() => handleOpen(params.row)}
          >
            <Tooltip title="View">
              <RemoveRedEyeIcon />
            </Tooltip>
          </button>

          <button
            className="btn btn-secondary"
            onClick={() =>
              navigate(`/admin/jobs/candidates/${params.row.slug}`)
            }
          >
            <Tooltip title="Jobseeker List">
              <FormatListBulletedIcon />
            </Tooltip>
          </button>
          <button
            className="btn btn-primary "
            onClick={() =>
              navigate(`/admin/jobs/addjob/copy/${params.row.slug}`)
            }
          >
            <Tooltip title="Copy Details">
              <ContentCopyIcon />
            </Tooltip>
          </button>
        </td>
      ),
    },
  ];

  const rows = skills
    ? skills.map((i) => ({
        id: i.id,
        emp_name: i.emp_name,
        title: i.title,
        company_name: i.company_name,
        location: i.location,
        cat_name: i.cat_name,
        created: i.created,
        jobseeker_count: i.jobseeker_count,
        status: i.status,
        slug: i.slug,
        contact_name:i.contact_name,
        contact_number:i.contact_number,
        work_type:i.work_type,
        url:i.url,
        description:i.description
      }))
    : listData.map((item) => ({
        id: item.id,
        emp_name: item.emp_name,
        title: item.title,
        company_name: item.company_name,
        location: item.location,
        cat_name: item.cat_name,
        created: item.created,
        jobseeker_count: item.jobseeker_count,
        status: item.status,
        slug: item.slug,
        contact_name:item.contact_name,
        contact_number:item.contact_number,
        work_type:item.work_type,
        url:item.url,
        description:item.description
      }));

  const handleSearch = (e) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);

    // Filter skills based on the keyword
    const filtered = listData.filter(
      (listData) =>
        listData.emp_name.toLowerCase().includes(keyword.toLowerCase()) ||
        listData.title.toLowerCase().includes(keyword.toLowerCase()) ||
        listData.company_name.toLowerCase().includes(keyword.toLowerCase()) ||
        listData.location.toLowerCase().includes(keyword.toLowerCase())
    );
    setSkills(filtered);
    // console.log(filtered);
  };

  const handleClearFilters = (e) => {
    e.preventDefault();
    setSearchKeyword("");
    setSkills();
  };

  const getCurrentJobCount = (currentJobCount) => {
    if(currentJobCount > totalJobsCount){
      Swal.fire({
        title: "Maximum jobs loaded",
        icon: "warning",
        confirmButtonText: "Close",
      });
      return totalJobsCount;
    }
    return currentJobCount;
  }

  return (
    <>
      <APNavBar />
      <div className="APBasic1 APManageSubadmin">
        <APSidebar />

        {loading ? (
          <>
            <div className="loader-container"></div>
          </>
        ) : (
          <>
            <div className="site-min-height">
              <div className="breadCumb1" role="presentation">
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<NavigateNextIcon fontSize="small" />}
                >
                  <Link
                    underline="hover"
                    color="inherit"
                    to={"/admin/admins/dashboard"}
                  >
                    Dashboard
                  </Link>
                  <Link to="" underline="hover" color="inherit">
                    Jobs
                  </Link>

                  <Typography color="text.primary">Job List</Typography>
                </Breadcrumbs>
              </div>
              <div className="ManageSubAdminHeader">
                <h2 className="">Jobs List</h2>

                <button
                  className="btn navButton1 APMSbutton"
                  onClick={() => navigate("/admin/jobs/addjob")}
                >
                  Add Job
                </button>
              </div>
              {listData != "" ? (
                <>
                  <div className="manageSubadminPart1 mb-3">
                    <form>
                      <div className="manageSubadminPart1Sec1">
                        <h4 className="mb-3">
                          Search Job by typing Employer name, title,company name
                          or location
                        </h4>
                        <div class="APDashboardSearchBx ">
                          <input
                            type="text"
                            id="form3Example1"
                            className="form-control"
                            name="searchQuery"
                            placeholder="Search.."
                            value={searchKeyword}
                            onChange={handleSearch}
                          />
                          {/* <button
                            className="btn btn-primary APButton3"
                            // disabled={currentPage === totalPages}
                            onClick={handleSearch}
                          >
                            Search
                          </button> */}
                        </div>
                        <button
                          className="btn btn-primary APButton3 float-end"
                          // disabled={currentPage === totalPages}
                          onClick={(e) => fetchMoreJobs(e, currentPage + 1)}
                        >
                          Load More
                        </button>
                      </div>
                    </form>
                    <h4>
                    {/* getCurrentJobCount(listData.length) */}
                      No. of Results 1 - {listData.length < totalJobsCount ? listData.length : totalJobsCount} of {totalJobsCount}
                    </h4>
                  </div>

                  <div className="manageSubadminpart3 mb-3">
                    {listData.length !== "" ? (
                      <>
                        <div className="interviewPreviewTable">
                          <div>
                            <DataGrid
                              rows={rows}
                              columns={columns}
                              initialState={{
                                pagination: {
                                  paginationModel: { page: 0, pageSize: 20 },
                                },
                              }}
                              pageSizeOptions={[20, 50, 100]}
                              checkboxSelection
                              onRowSelectionModelChange={(
                                newRowSelectionModel
                              ) => {
                                setSelectedIds(newRowSelectionModel);
                                console.log(selectedIds);
                                // console.log("object")
                              }}
                              rowSelectionModel={selectedIds}
                              {...listData}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>No Data</>
                    )}
                  </div>

                  <div className="manageSubadminpart3">
                    <div className="functionalityButton">
                      <button
                        type="button"
                        className="btn btn-primary APButton3"
                        onClick={() => handleMultipleActivate()}
                      >
                        ACTIVATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary APButton3"
                        onClick={() => handleMultipleDeactivate()}
                      >
                        DEACTIVATE
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary APButton4"
                        onClick={() => handleMultipleDelete()}
                      >
                        DELETE
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="appliedJobListNoData mt-5">
                  No Records Found
                </div>
              )}
            </div>

            <div>
              <Modal
                className="modalMain"
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="modal adminModal modal-content">
                  <IconButton
                    onClick={handleClose}
                    className="close-button"
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                    }}
                  >
                    &times;
                  </IconButton>

                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  ></Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                    {selectedPayment && (
                      <div className="modals ">
                        <div className="modalHead">
                          <h1>{selectedPayment.title}</h1>
                        </div>
                        <div className="modalBody mt-4">
                          <div className="modalParent">
                            <div className="modalLeft">Search Count: </div>
                            <div className="modalRight">
                              {selectedPayment.jobseeker_count}
                            </div>
                          </div>

                          <div className="modalParent">
                            <div className="modalLeft">Job View Count: </div>
                            <div className="modalRight">
                              {/* {selectedPayment.last_name} */}
                            </div>
                          </div>

                          <div className="modalParent">
                            <div className="modalLeft">Employer Name: </div>
                            <div className="modalRight">
                              {selectedPayment.emp_name}
                            </div>
                          </div>
                          <div className="modalParent">
                            <div className="modalLeft">Job Title: </div>
                            <div className="modalRight">
                              {selectedPayment.title}
                            </div>
                          </div>

                          <div className="modalParent">
                            <div className="modalLeft">Category: </div>
                            <div className="modalRight">
                              {selectedPayment.cat_name}
                            </div>
                          </div>

                          <div className="modalParent">
                            <div className="modalLeft">Company Name: </div>
                            <div className="modalRight">
                              {selectedPayment.company_name}
                            </div>
                          </div>
                          <div className="modalParent">
                            <div className="modalLeft">Work Type: </div>
                            <div className="modalRight">
                              {getWorkType(selectedPayment.work_type)}
                            </div>
                          </div>
                          <div className="modalParent">
                            <div className="modalLeft">Contact Name: </div>
                            <div className="modalRight">
                              {selectedPayment.contact_name}
                            </div>
                          </div>
                          <div className="modalParent">
                            <div className="modalLeft">Contact Number: </div>
                            <div className="modalRight">
                              {selectedPayment.contact_number}
                            </div>
                          </div>
                          <div className="modalParent">
                            <div className="modalLeft">Company Website: </div>
                            <div className="modalRight">
                              {selectedPayment.url}
                            </div>
                          </div>
                          <div className="modalParent">
                            <div className="modalLeft">Job Description: </div>
                            <div className="modalRight">
                              {selectedPayment.description
                                ? HTMLReactParser(selectedPayment.description)
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Typography>
                </Box>
              </Modal>
            </div>
            <APFooter />
          </>
        )}
      </div>
    </>
  );
};

export default NewJobList;
