import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import { useTranslation } from "react-i18next";

const BrowseJobToggler = () => {
  const [toggle1, setToggle1] = useState(true);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const [toggle4, setToggle4] = useState(false);
  const [toggle5, setToggle5] = useState(false);

  const [byTitle, setByTitle] = useState([]);
  const [byCategory, setByCategory] = useState([]);
  const [bySkill, setBySkill] = useState([]);
  const [popularSearch, setPopularSearch] = useState([]);
  const [byCountry, setByCountry] = useState([]);

  const [titleActive, setTitleActive] = useState(true);
  const [skillActive, setSkillActive] = useState(false);
  const [categoryActive, setCategoryActive] = useState(false);
  const [popularSearchActive, setPopularSearchActive] = useState(false);
  const [countryActive, setCountryActive] = useState(false);

  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(BaseApi + `/home`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setByTitle(response.data.response.job_title);
        setByCategory(response.data.response.categories);
        setBySkill(response.data.response.skillList);
        setPopularSearch(response.data.response.keywords);
        setByCountry(response.data.response.countryList);
        console.log(bySkill);
      } catch (error) {
        console.log("Cannot get home page data!");
      }
    };
    getData();
    window.scrollTo(0, 0);
  }, []);

  const Title = () => {
    setTitleActive(true);
    setSkillActive(false);
    setCategoryActive(false);
    setPopularSearchActive(false);
    setCountryActive(false);
    setToggle1(true);
    setToggle2(false);
    setToggle3(false);
    setToggle4(false);
    setToggle5(false);
  };
  const Skill = () => {
    setSkillActive(true);
    setTitleActive(false);
    setCategoryActive(false);
    setPopularSearchActive(false);
    setCountryActive(false);
    setToggle1(false);
    setToggle2(true);
    setToggle3(false);
    setToggle4(false);
    setToggle5(false);
  };
  const Category = () => {
    setSkillActive(false);
    setTitleActive(false);
    setCategoryActive(true);
    setPopularSearchActive(false);
    setCountryActive(false);
    setToggle1(false);
    setToggle2(false);
    setToggle3(true);
    setToggle4(false);
    setToggle5(false);
  };
  const popularSearches = () => {
    setSkillActive(false);
    setTitleActive(false);
    setCategoryActive(false);
    setPopularSearchActive(true);
    setCountryActive(false);
    setToggle1(false);
    setToggle2(false);
    setToggle3(false);
    setToggle4(true);
    setToggle5(false);
  };

  const country = () => {
    setSkillActive(false);
    setTitleActive(false);
    setCategoryActive(false);
    setPopularSearchActive(false);
    setCountryActive(true);
    setToggle1(false);
    setToggle2(false);
    setToggle3(false);
    setToggle4(false);
    setToggle5(true);
  };

  const handleClickTitle = (title) => {
    sessionStorage.setItem("keywordTitle", title);
  };

  const handleClick = (id) => {
    sessionStorage.setItem("catId", id);
  };

  const handleClickForCountry = (id) => {
    sessionStorage.setItem("countryId", id);
  };

  return (
    <>
      <div className="buttons BrowseJobstab">
        <button
          onClick={() => Title()}
          className={`btn ${titleActive === true && "active"}`}
        >
          {t("userpage.byTitle")}
        </button>
        <button
          onClick={() => country()}
          className={`btn ${countryActive === true && "active"}`}
        >
          {t("userpage.byCountry")}
        </button>
        <button
          onClick={() => Skill()}
          className={`btn ${skillActive === true && "active"}`}
        >
          {t("userpage.bySkills")}
        </button>
        <button
          onClick={() => Category()}
          className={`btn ${categoryActive === true && "active"}`}
        >
          {t("userpage.byCategory")}
        </button>
        <button
          onClick={() => popularSearches()}
          className={`btn ${popularSearchActive === true && "active"}`}
        >
          {t("userpage.popularSearches")}
        </button>
      </div>
      <div className="BrowseJobstabs toggledata">
        {toggle1 && (
          <div className="first">
            <div className="row">
              {byTitle.slice(0,25).map((i) => {
                return (
                  <>
                    <div className="col-md-3 col-lg-3">
                      <Link
                        to={`/jobs/searchjob/${i.slug}`}
                        onClick={() => handleClickTitle(i.title)}
                        className="list"
                      >
                        <p key={i.id}>{i.title}</p>
                      </Link>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        )}
        {toggle5 && (
          <div className="first">
            <div className="row">
              {byCountry.slice(0,25).map((i) => {
                return (
                  <>
                    <div className="col-md-3 col-lg-3">
                      <Link
                        to="/searchjob"
                        onClick={() => handleClickForCountry(i.id)}
                        className="list"
                      >
                        <p key={i.id}>{i.location_country}</p>
                      </Link>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        )}
        {toggle2 && (
          <div className="first">
            <div className="row">
              {bySkill.slice(0,25).map((i) => {
                return (
                  <>
                    <div className="col-md-3 col-lg-3">
                      <Link to={`/jobs/searchjob/${i.slug}`} className="list">
                        <p key={i.id}>{i.name}</p>
                      </Link>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        )}
        {toggle3 && (
          <div className="first">
            <div className="row">
              {byCategory.slice(0,25).map((i) => {
                return (
                  <>
                    <div className="col-md-3 col-lg-3">
                      <Link
                        to="/searchjob"
                        onClick={() => handleClick(i.id)}
                        className="list"
                      >
                        <p key={i.id}>{i.name}</p>
                      </Link>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        )}
        {toggle4 && (
          <div className="first">
            <div className="row">
              {popularSearch.slice(0,25).map((i) => {
                return (
                  <>
                    <div className="col col-lg-3">
                      <Link to={`/jobs/searchjob/${i.name}`} className="list">
                        <p key={i.id}>{i.name}</p>
                      </Link>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BrowseJobToggler;
