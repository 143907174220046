import React, { useState, useEffect } from "react";
import { IconButton, styled } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
const ScrollToTopButton = styled(IconButton)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(4), // Adjust the bottom position as needed
  right: theme.spacing(4), // Adjust the right position as needed
  zIndex: 1000,
}));

const ScrollToTop = () => {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {showButton && (
        <span title="Scroll to Top" style={{ backgroundColor: "white",
        borderRadius: "10px",
        borderColor: "red",
        }}>
          <ScrollToTopButton onClick={scrollToTop} color="primary" aria-label="scroll-to-top">
            <ArrowCircleUpIcon sx={{ fontSize: "40px", color: "rgb(145, 0, 5)"}}/>
          </ScrollToTopButton>
        </span>
      )}
    </>
  );
};

export default ScrollToTop;
